import React from "react"

import ContactMe from "../forms/contactme"

export default () => (
	<section className="impress_area p_120">
		<div className="container">
			<div className="impress_inner">
				<h2>Want a personalized demo?</h2>
				<p>We love meeting our users. Get in touch with us and let's schedule a remote demo!</p>
				<ContactMe reason="requesting personal demo"/>
			</div>
		</div>
	</section>
)
