import React from "react"

import { getSession } from "../../../services/auth"

import ContactMe from "../forms/contactme"

import neuron from "../static/img/neuron.png"
import aws_diagram from "../static/img/aws_diagram.png"
import edu_diagram from "../static/img/edu_diagram.png"
import banana from "../static/img/banana.png"
import { Link } from "../../../services/routes" // "gatsby"
import routes from "../../../services/routes"

export default () => {

	const session = getSession();

	return (
	<section className="made_life_area p_120">
		<div className="container">
			<div className="made_life_inner">
				<ul className="nav nav-tabs" id="myTab" role="tablist">
					<li className="nav-item">
						<a
							className="nav-link active"
							id="home-tab"
							data-toggle="tab"
							href="#home"
							role="tab"
							aria-controls="home"
							aria-selected="true"
						>
							Individuals
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							id="profile-tab"
							data-toggle="tab"
							href="#profile"
							role="tab"
							aria-controls="profile"
							aria-selected="false"
						>
							Teams
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							id="contact-tab"
							data-toggle="tab"
							href="#contact"
							role="tab"
							aria-controls="contact"
							aria-selected="false"
						>
							Teachers
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							id="edge-tab"
							data-toggle="tab"
							href="#edge"
							role="tab"
							aria-controls="edge"
							aria-selected="false"
						>
							Developers
						</a>
					</li>
				</ul>
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="row made_life_text">
							<div className="col-lg-6">
								<div className="left_side_text">
									<h3>
										Keep organized notes,
										<br />
										mark up documents easily
									</h3>
									<h6>Scratchwork saves time</h6>
									<p>
										Scratchwork is ideal for rough, unpolished ideas that you want to keep, but aren't ready to publish or
										present to the CEO. Don't waste time perfecting something you might erase later.
									</p>
									{ (session.user === null) &&
										<Link className="main_btn" to={routes.signup}>
											Sign Up
										</Link>
									}
								</div>
							</div>
							<div className="col-lg-6">
								<div className="chart_img">
									<img className="img-fluid" src={neuron} />
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
						<div className="row made_life_text">
							<div className="col-lg-6">
								<div className="left_side_text">
									<h3>
										Share ideas in person
										<br />
										and remotely
									</h3>
									<h6>Whiteboard photos become live documents here</h6>
									<p>
										In the office, a whiteboard conversation often ends up as a picture on someone's phone. Scratchwork
										helps your team continue the collaborative process after the whiteboard has been erased.
									</p>
									{ (session.user === null) &&
										<Link className="main_btn" to={routes.signup}>
											Sign Up
										</Link>
									}
								</div>
							</div>
							<div className="col-lg-6">
								<div className="chart_img">
									<img className="img-fluid" src={aws_diagram} />
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
						<div className="row made_life_text">
							<div className="col-lg-6">
								<div className="left_side_text">
									<h3>
										Engage students without
										<br />
										changing your lesson plan
									</h3>
									<h6>Scratchwork is ideal for classrooms on a budget</h6>
									<p>
										Let students solve problems on paper and submit photos of their work. Scratchwork makes it easy to
									</p>
									<ul>
										<li>Prepare class worksheets on the fly</li>
										<li>Incorporate multiple students' work in a single class worksheet</li>
										<li>Use Chromebooks and iPads effectively in STEM classrooms</li>
										<li>Hold online office hours and tutoring sessions</li>
									</ul>
									{ (session.user === null) &&
										<Link className="main_btn" to={routes.signup}>
											Sign Up
										</Link>
									}
								</div>
							</div>
							<div className="col-lg-6">
								<div className="chart_img">
									<img className="img-fluid" src={edu_diagram} />
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="edge" role="tabpanel" aria-labelledby="edge-tab">
						<div className="row made_life_text">
							<div className="col-lg-6">
								<div className="left_side_text">
									<h3>
										Make your app paper-friendly
										<br />
										with our API.
									</h3>
									<h6>Join us in building the future for handwritten work.</h6>
									<p>Get in touch with us to learn more. </p>
									<br />
									<ContactMe reason="developer curiosity" />
								</div>
							</div>
							<div className="col-lg-6">
								<div className="chart_img">
									<img className="img-fluid" src={banana} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	);
};
